import React from 'react';
import { Carousel, Typography, Rate, Row, Col, Button } from 'antd';
import '../About/About.css';
import aboutimg from "../../assets/images/video-cover-image-4.jpg";

const { Title, Text } = Typography;


const AboutUs = () => {
  
  return (
    
    <div className='aboutSection'>
      <div className='custom_container'>
            <Row className='aboutInnerWrap'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
        <div className='aboutLeftContent'>
        <Title level={2} className='customHeading'>What is Litle Jack Fullers?</Title>
        <Title level={1} className='customHeading'>Delivered.</Title>

        <p className='aboutDetails'>Litle Jack Fullers makes it incredibly easy for you to discover and get what you want. Delivered to you – quickly, reliably and affordably.


</p>
           <Button className="aboutBtn">
            <div className="playBtn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" className="playBtnSvg">
              <path fill="currentColor" d="M23.987 22.973c0-1.109.792-1.604 1.775-1.103L39.1 28.667c.98.5.984 1.308 0 1.81l-13.338 6.796c-.98.5-1.775.004-1.775-1.103V22.973z"></path></svg>
              </div><span className="watchLink">Watch video</span>
              </Button>
        </div>
       
        </Col>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
        <div className='aboutBanner'>
        <img src={aboutimg} alt='Litle Jack Fullers' />
        </div>
        </Col>
      </Row>
    </div>
    </div>
  );
};

export default AboutUs;
