// src/components/features/Cart/Checkout.js
import React, { useContext } from 'react';
import CartContext from '../../../context/CartContext';
import { Button, List } from 'antd';

const Checkout = () => {
  const { state } = useContext(CartContext);

  return (
    <div>
      <h2>Checkout</h2>
      <List
        bordered
        dataSource={state.items}
        renderItem={item => (
          <List.Item>
            {item.name} - ${item.price}
          </List.Item>
        )}
      />
      <h3>Total: ${state.totalAmount}</h3>
      <Button type="primary">Place Order</Button>
    </div>
  );
};

export default Checkout;
