
// src/components/pages/Order.js
import React from 'react';
import Checkout from '../features/Cart/Checkout';

const Order = () => {
  return (
    <div>
      <h1>Your Order</h1>
      <Checkout />
    </div>
  );
};

export default Order;
