// src/components/BannerSlider.js
import React from 'react';
import { Carousel } from 'antd';

const BannerSlider = () => {
  return (
    <Carousel autoplay>
      <div className='SliderBanner'>
        <img src="https://consumer-static-assets.wolt.com/frontpage-assets/hero-images/1_Monday.jpg" alt="Banner 1" style={{ width: '100%' }} />
      </div>
      <div className='SliderBanner'>
        <img src="https://consumer-static-assets.wolt.com/frontpage-assets/hero-images/2_Tuesday.jpg" alt="Banner 2" style={{ width: '100%' }} />
      </div>
      <div className='SliderBanner'>
        <img src="https://consumer-static-assets.wolt.com/frontpage-assets/hero-images/3_Wednesday.jpg" alt="Banner 3" style={{ width: '100%' }} />
      </div>
      <div className='SliderBanner'>
        <img src="https://consumer-static-assets.wolt.com/frontpage-assets/hero-images/4_Thursday.jpg" alt="Banner 3" style={{ width: '100%' }} />
      </div>
    </Carousel>
  );
};

export default BannerSlider;
