// src/components/layouts/Header.js
import React, { useContext, useState } from 'react';
import { Layout, Menu, Badge, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';
import CartContext from '../../context/CartContext';
import '../../assets/css/Header.css';

const { Header } = Layout;

const MyHeader = () => {
  const { state } = useContext(CartContext);
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <Layout className='custom_layout'>
    <Header className="custom-header">
        <Link to="/" className="logo">
          <img src="https://a.storyblok.com/f/177063/126x125/4567c283c7/tfk-logo.svg" alt="Restaurant Logo" />
        </Link>
        {/* <div className="menu-toggle" onClick={toggleMenu}>
          <span>☰</span>
        </div> */}
        <Menu className={`menu ${menuVisible ? 'active' : ''}`} theme="light" mode="horizontal" selectable={false}>
          <Menu.Item key="locations" className='active_menu'>
            <Link to="/">Online Order</Link>
          </Menu.Item>
          <Menu.Item key="locations">
            <Link to="/">Reservation</Link>
          </Menu.Item>
          <Menu.Item key="locations">
            <Link to="/">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="signin" className="btn_sign">
            <Link to="/account/signin" >Sign In</Link>
          </Menu.Item>
          <Menu.Item key="signup" className="btn_join">
            <Link to="/account/signup" >Join Now</Link>
          </Menu.Item>
          <Menu.Item key="cart" className="cart-item">
            <Link to="/cart">
              <Badge count={state.items.length}>
                <svg className="cart-icon" width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-15c8de9a="" fill-rule="evenodd" clip-rule="evenodd" d="M22.889 6.66669H19.5031C19.0617 2.9179 15.8654 0 12 0C8.13464 0 4.93841 2.9179 4.49702 6.66669H1.11111C0.497446 6.66669 0 7.16414 0 7.77781V27.3334C0 27.9471 0.497446 28.4445 1.11111 28.4445H22.889C23.5026 28.4445 24.0001 27.9471 24.0001 27.3334V7.77781C24.0001 7.16414 23.5026 6.66669 22.889 6.66669ZM11.9997 2.22239C14.6377 2.22239 16.8341 4.14768 17.2586 6.66685H6.74088C7.16532 4.14768 9.36172 2.22239 11.9997 2.22239ZM2.22215 26.2225H21.7778V8.88908H19.5555V12.2224C19.5555 12.8361 19.0581 13.3335 18.4444 13.3335C17.8308 13.3335 17.3333 12.8361 17.3333 12.2224V8.88908H6.66661V12.2224C6.66661 12.8361 6.16916 13.3335 5.5555 13.3335C4.94183 13.3335 4.44438 12.8361 4.44438 12.2224V8.88908H2.22215V26.2225Z" fill="#3B3D2F"></path></svg>
              </Badge>
            </Link>
          </Menu.Item>
        </Menu>
    </Header>
    </Layout>
  );
};

export default MyHeader;
