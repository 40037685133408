import React from 'react';
import { Layout, Row, Col, Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined, ShoppingCartOutlined, UserOutlined, MoreOutlined } from '@ant-design/icons'; // Add icons
import gplay from "../../assets/images/play_store.png";
import appstore from "../../assets/images/app_store.png";
import '../layouts/Footer.css';

const { Title, Text } = Typography;
const currentYear = new Date().getFullYear();
const { Footer } = Layout;

const MyFooter = () => {
  return (
    <>
      {/* Desktop Footer */}
      <Footer className="footerSection desktopFooter">
        <div className="topFooter">
          <Row gutter={20}>
            <Col xs={24} sm={24} md={16} xl={16} xxl={16}>
              <div className="footerLeft">
                <Link to="/" className="ftLogo">
                  <img
                    src="https://a.storyblok.com/f/177063/126x125/4567c283c7/tfk-logo.svg"
                    alt="Restaurant Logo"
                  />
                </Link>

                <Menu mode="horizontal" className="footerMenu">
                  <Menu.Item key="1">
                    <a href="/disclaimer">About Us</a>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <a href="/gallery">Gallery</a>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <a href="/privacy">Privacy Policy</a>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <a href="/termsconditions">Terms & Conditions</a>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <a href="/disclaimer">Allergy Information</a>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <a href="/nutritioninfo">Nutritional Information</a>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <a href="/contact">Contact Us</a>
                  </Menu.Item>
                </Menu>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} xl={8} xxl={8}>
              <Title level={4} className="footerHeading">
                Download App
              </Title>
              <div className="footerAppStore">
                <Link className="appStoreBtn">
                  <img src={gplay} alt="Google Play" />
                </Link>
                <Link className="appStoreBtn">
                  <img src={appstore} alt="App Store" />
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="copyrightFooter">
          Little Jack Fullers {currentYear} Created by PDQ2 Group Limited
        </div>
      </Footer>

      {/* Mobile Footer */}
      <Footer className="mobileFooter">
        <Menu mode="horizontal" className="mobileMenu">
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="cart" icon={<ShoppingCartOutlined />}>
            <Link to="/cart">Cart</Link>
          </Menu.Item>
          <Menu.Item key="account" icon={<UserOutlined />}>
            <Link to="/account">My Account</Link>
          </Menu.Item>
          <Menu.Item key="more" icon={<MoreOutlined />}>
            <Link to="/more">More</Link>
          </Menu.Item>
        </Menu>
      </Footer>
    </>
  );
};

export default MyFooter;
