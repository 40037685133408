// src/App.js
import React from 'react';
import { CartProvider } from './context/CartContext';
import AppRouter from './router/AppRouter';
import './assets/css/styles.css'; // Import global styles

const App = () => {
  return (
    <CartProvider>
      <AppRouter />
    </CartProvider>
  );
};

export default App;
