// src/router/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyHeader from '../components/layouts/Header';
import MyFooter from '../components/layouts/Footer';
import Home from '../components/pages/Home';
import Order from '../components/pages/Order';

const AppRouter = () => {
  return (
    <Router>
      <MyHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/order" element={<Order />} />
      </Routes>
      <MyFooter />
    </Router>
  );
};

export default AppRouter;
