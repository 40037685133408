// src/components/HowItWorks.js
import React from 'react';
import { Col, Typography,Row } from 'antd';
import './HowItWorks.css'; // Optional: for styling


const { Title } = Typography;

const HowItWorks = () => {
  const steps = [
    {
      title: 'Favourite Food Delivery Partner',
      icon: (
        <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="#000"/>
          <path d="M12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" fill="#000"/>
        </svg>
      ),
      description: 'It is a long established fact that a reader of a page when looking at its layout.',
    },
    {
      title: 'Easy To Order',
      icon: (
        <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 6H4c-1.1 0-1.99.9-1.99 2L2 20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 14H4V8h16v12z" fill="#000"/>
        </svg>
      ),
      description: 'It is a long established fact that a reader of a page when looking at its layout.',
    },
    {
      title: 'Fastest Delivery',
      icon: (
        <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 5H7l-1-1H2v2h3l1 1h13v2h2V5h-2zm0 4H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm-8 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="#000"/>
        </svg>
      ),
      description: 'It is a long established fact that a reader of a page when looking at its layout.',
    },
    {
      title: 'Best Quality',
      icon: (
        <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17.93c-3.95.49-7.28-2.69-7.78-6.52C6.1 12.56 7.47 12 9 12h6c1.53 0 2.9.56 3.78 1.41C19.28 17.24 15.95 19.42 12 19.93z" fill="#000"/>
        </svg>
      ),
      description: 'It is a long established fact that a reader of a page when looking at its layout.',
    },
  ];

  return (
    
    <div className="howItWorkSection">
      <div className='custom_container'>
      <Title level={2} className='customHeading'>How It Works</Title>
      <Row gutter={20} className="steps">
        {steps.map((step, index) => (
          <Col xs={24} sm={6} md={6} xl={6} xxl={6} className="step" key={index}>
            {step.icon}
            <Title level={4}>{step.title}</Title>
            <p>{step.description}</p>
          </Col>
        ))}
      </Row>
     </div>
     </div>
  );
};

export default HowItWorks;
