// src/TopReviews.js

import React from 'react';
import { Carousel, Typography, Rate, Row, Col } from 'antd';
import moment from 'moment';
import '../Reviews/TopReviews.css';

const { Title, Text } = Typography;

const reviews = [
  {
    name: "Alice",
    photo: "https://via.placeholder.com/100",
    text: "Great product! Highly recommend.",
    rating: 5,
    date: moment().subtract(1, 'days').format('MMMM D, YYYY'),
  },
  {
    name: "Bob",
    photo: "https://via.placeholder.com/100",
    text: "Very satisfied with my purchase.",
    rating: 4,
    date: moment().subtract(2, 'days').format('MMMM D, YYYY'),
  },
  {
    name: "Charlie",
    photo: "https://via.placeholder.com/100",
    text: "Customer service was excellent.",
    rating: 5,
    date: moment().subtract(3, 'days').format('MMMM D, YYYY'),
  },
  {
    name: "David",
    photo: "https://via.placeholder.com/100",
    text: "Will buy again!",
    rating: 4,
    date: moment().subtract(4, 'days').format('MMMM D, YYYY'),
  },
  {
    name: "Eve",
    photo: "https://via.placeholder.com/100",
    text: "Five stars for quality and delivery.",
    rating: 5,
    date: moment().subtract(5, 'days').format('MMMM D, YYYY'),
  },
  {
    name: "John",
    photo: "https://via.placeholder.com/100",
    text: "Excellent value for money!",
    rating: 5,
    date: moment().subtract(6, 'days').format('MMMM D, YYYY'),
  },
  {
    name: "Noel",
    photo: "https://via.placeholder.com/100",
    text: "Amazing product, will buy again!",
    rating: 5,
    date: moment().subtract(7, 'days').format('MMMM D, YYYY'),
  },
];

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const TopReviews = () => {
  const groupedReviews = chunkArray(reviews, 4);

  return (
    <div className='reviewSection'>
      <div className='custom_container'>
      <Title level={2} className='customHeading textCenter' >Top Customer Reviews</Title>
      <Carousel dots={false} autoplay>
        {groupedReviews.map((group, groupIndex) => (
          <div key={groupIndex}>
            <Row gutter={16} justify="center">
              {group.map((review, idx) => (
                <Col key={idx} span={6}> {/* Each review takes 6/24 of the width (4 boxes in a row) */}
                  <div className='reviewBoxWrap'>
                    <div className='reviewInfo'>
                      <div className='reviewUserImg'>
                      <img src={review.photo} alt={review.name} />
                      </div>
                      <div className='reviewUserName'>
                      <Title level={5} className='reviewHeading'>{review.name}</Title>
                      </div>
                    </div>
                    <Text className='reviewText'>{review.text}</Text>
                    <div className='ratingStar'>
                      <Rate allowHalf disabled defaultValue={review.rating} />
                      <Text type="secondary" className='reviewDate'>{review.date}</Text>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Carousel>
    </div>
    </div>
  );
};

export default TopReviews;
