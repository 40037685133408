// src/context/CartContext.js
import React, { createContext, useReducer } from 'react';

const CartContext = createContext();

const initialState = {
  items: [],
  totalAmount: 0,
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      const updatedItems = [...state.items, action.payload];
      const updatedTotalAmount = updatedItems.reduce((total, item) => total + item.price, 0);
      return { items: updatedItems, totalAmount: updatedTotalAmount };
    case 'REMOVE_ITEM':
      const filteredItems = state.items.filter(item => item.id !== action.payload.id);
      const newTotalAmount = filteredItems.reduce((total, item) => total + item.price, 0);
      return { items: filteredItems, totalAmount: newTotalAmount };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
